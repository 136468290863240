<template>
    <div class="error">
        <!-- 警告圖片 -->
        <svg class="error-img" viewBox="0 0 251.57 182.5">
            <!-- viewBox="0 0 251.57 215.36" -->
            <defs>
                <linearGradient id="a" x1="6.71" y1="218.45" x2="6.71" y2="193.9" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stop-color="#a1041b" />
                    <stop offset="1" stop-color="#f3525f" />
                </linearGradient>
                <linearGradient id="q" x1="49.08" y1="204.89" x2="202.46" y2="51.51" xlink:href="#a" />
                <linearGradient id="r" x1="28.95" y1="184.76" x2="182.33" y2="31.38" xlink:href="#a" />
                <linearGradient id="s" x1="53.41" y1="209.22" x2="206.79" y2="55.84" xlink:href="#a" />
            </defs>
            <path fill="url('#q')"
                d="M202.37,179.78H49.19a23.56,23.56,0,0,1-20.4-35.34L105.38,11.78a23.56,23.56,0,0,1,40.81,0l76.59,132.66a23.56,23.56,0,0,1-20.41,35.34Zm-76.59-168a11.7,11.7,0,0,0-10.24,5.91L39,150.31A11.82,11.82,0,0,0,49.19,168H202.37a11.82,11.82,0,0,0,10.24-17.73L136,17.65A11.7,11.7,0,0,0,125.78,11.74Z" />
            <rect x="119.91" y="58.58" width="11.74" height="58.7" fill="url('#r')" />
            <path fill="url('#s')" d="M125.78,144.67a7.83,7.83,0,1,1,7.83-7.83A7.84,7.84,0,0,1,125.78,144.67Z" />
        </svg>

        <div class="error-text">
            <p class="content">
                {{ $t("error.noserice.1") }}
            </p>
            <p class="content">
                {{ $t("error.noserice.2") }}
            </p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.error {
    @include flex(column, center, center);
    color: #fff;
    height: 100vh;
    width: 100%;

    &-img {
        width: 20%;
    }

    &-text {
        border: 1px solid #fff;
        color: red;
        font-size: 18px;
        text-align: center;
        line-height: 1.6;
        margin-top: 50px;
        padding: 1rem;
        width: 40%;
    }
}

@include large {
    .error {
        &-img {
            width: 25%;
        }

        &-text {
            font-size: 16px;
            width: 50%;
        }
    }
}

@include big {
    .error {
        &-img {
            width: 30%;
        }

        &-text {
            width: 70%;
        }
    }
}

@include medium {
    .error {
        &-img {
            width: 35%;
        }

        &-text {
            width: 70%;
        }
    }
}

@include small {
    .error {
        &-img {
            width: 60%;
        }

        &-text {
            width: 90%;
        }
    }
}
</style>
